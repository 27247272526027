var render = function () {
  var _vm$$v, _vm$$v$subscription, _vm$$v2, _vm$$v2$subscription, _vm$registrationData, _vm$registrationData2, _vm$$v3, _vm$$v3$subscription, _vm$$v4, _vm$$v4$subscription, _vm$$v5, _vm$$v5$subscription, _vm$$v6, _vm$$v6$subscription, _vm$$v7, _vm$$v7$subscription, _vm$$v7$subscription$, _vm$$v8, _vm$$v8$subscription, _vm$$v8$subscription$, _vm$$v9, _vm$$v9$subscription, _vm$$v9$subscription$, _vm$subscription, _vm$subscription$seri, _vm$seriesOfSharesLis, _vm$subscription2, _vm$subscription3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['add-new-subscription', _vm.$route.name == 'addNewSubscriptionSuccess' ? 'margin-top' : 0]
  }, [_vm.$route.name != 'addNewSubscriptionSuccess' ? _c('div', {
    staticClass: "add-new-subscription__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "outline gn-secondary",
    attrs: {
      "arrowIconLeft": true
    },
    on: {
      "click": _vm.gotToSubscriptionsPage
    }
  }, [_vm._v("Go back")])], 1) : _vm._e(), _c('div', {
    staticClass: "add-new-subscription__box"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', [_vm._v(_vm._s(_vm.viewType === 'preview' ? 'Preview' : _vm.isEditMode ? 'Edit' : 'Add new') + " subscription")]), _c('p', {
    staticClass: "title mb-5"
  }, [_vm._v("Subscription details")]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form__row"
  }, [_c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "label": "CODE",
      "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$subscription = _vm$$v.subscription) === null || _vm$$v$subscription === void 0 ? void 0 : _vm$$v$subscription.code,
      "disabled": _vm.isSubscriptionDisabled
    },
    model: {
      value: _vm.subscription.code,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription, "code", $$v);
      },
      expression: "subscription.code"
    }
  })], 1), _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": "AGREEMENT DATE",
      "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$subscription = _vm$$v2.subscription) === null || _vm$$v2$subscription === void 0 ? void 0 : _vm$$v2$subscription.agreementDate,
      "disabled": _vm.isSubscriptionDisabled
    },
    model: {
      value: _vm.subscription.agreementDate,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription, "agreementDate", $$v);
      },
      expression: "subscription.agreementDate"
    }
  })], 1)]), _c('div', {
    staticClass: "form__row"
  }, [_c('div', {
    staticClass: "form__col"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": "REGISTRATION DATE",
      "value": (_vm$registrationData = _vm.registrationData) === null || _vm$registrationData === void 0 ? void 0 : _vm$registrationData.date,
      "disabled-date": _vm.registerDateDisabled,
      "disabled": !_vm.canRegister
    },
    on: {
      "change": _vm.setRegistrationDate
    }
  })], 1), _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "label": "REGISTRATION STATUS",
      "value": ((_vm$registrationData2 = _vm.registrationData) === null || _vm$registrationData2 === void 0 ? void 0 : _vm$registrationData2.status) || 'Unregistered',
      "disabled": true
    }
  })], 1)]), !_vm.isLlc ? [_c('div', {
    staticClass: "form__row"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.usedCurrencies,
      "label": "CURRENCY",
      "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$subscription = _vm$$v3.subscription) === null || _vm$$v3$subscription === void 0 ? void 0 : _vm$$v3$subscription.currency,
      "disabled": _vm.isSubscriptionDisabled
    },
    model: {
      value: _vm.subscription.currency,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription, "currency", $$v);
      },
      expression: "subscription.currency"
    }
  })], 1)] : _vm._e(), _c('div', {
    staticClass: "form__row"
  }, [_c('sygni-toggle-switch', {
    attrs: {
      "label": "INCLUDE TEMPLATES"
    },
    model: {
      value: _vm.includeTemplates,
      callback: function callback($$v) {
        _vm.includeTemplates = $$v;
      },
      expression: "includeTemplates"
    }
  })], 1), _vm.includeTemplates ? [_c('div', {
    staticClass: "form__row"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.templateOptions,
      "label": "STATEMENT ON TAKING UP NEW SHARES TEMPLATE",
      "searchable": true,
      "validation": (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : (_vm$$v4$subscription = _vm$$v4.subscription) === null || _vm$$v4$subscription === void 0 ? void 0 : _vm$$v4$subscription.productAgreementTemplateId,
      "disabled": _vm.isSubscriptionDisabled
    },
    model: {
      value: _vm.subscription.productAgreementTemplateId,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription, "productAgreementTemplateId", $$v);
      },
      expression: "subscription.productAgreementTemplateId"
    }
  })], 1), _c('div', {
    staticClass: "form__row"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.templateOptions,
      "label": "LIST OF PURCHASERS OF SHARES TEMPLATE",
      "searchable": true,
      "validation": (_vm$$v5 = _vm.$v) === null || _vm$$v5 === void 0 ? void 0 : (_vm$$v5$subscription = _vm$$v5.subscription) === null || _vm$$v5$subscription === void 0 ? void 0 : _vm$$v5$subscription.listOfPurchasersOfSharesTemplateId,
      "disabled": _vm.isSubscriptionDisabled
    },
    model: {
      value: _vm.subscription.listOfPurchasersOfSharesTemplateId,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription, "listOfPurchasersOfSharesTemplateId", $$v);
      },
      expression: "subscription.listOfPurchasersOfSharesTemplateId"
    }
  })], 1)] : _vm._e(), _vm.isLlc ? [_c('div', {
    staticClass: "form__row"
  }, [_c('div', {
    staticClass: "form__col"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.usedCurrencies,
      "label": "CURRENCY",
      "validation": (_vm$$v6 = _vm.$v) === null || _vm$$v6 === void 0 ? void 0 : (_vm$$v6$subscription = _vm$$v6.subscription) === null || _vm$$v6$subscription === void 0 ? void 0 : _vm$$v6$subscription.currency,
      "disabled": _vm.isSubscriptionDisabled
    },
    model: {
      value: _vm.subscription.currency,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription, "currency", $$v);
      },
      expression: "subscription.currency"
    }
  })], 1), _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "label": "INSTRUMENT COUNT",
      "displayFormat": "integer",
      "min": 1,
      "validation": (_vm$$v7 = _vm.$v) === null || _vm$$v7 === void 0 ? void 0 : (_vm$$v7$subscription = _vm$$v7.subscription) === null || _vm$$v7$subscription === void 0 ? void 0 : (_vm$$v7$subscription$ = _vm$$v7$subscription.llcShares) === null || _vm$$v7$subscription$ === void 0 ? void 0 : _vm$$v7$subscription$.instrumentCount,
      "disabled": _vm.isSubscriptionDisabled
    },
    model: {
      value: _vm.subscription.llcShares.instrumentCount,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription.llcShares, "instrumentCount", $$v);
      },
      expression: "subscription.llcShares.instrumentCount"
    }
  })], 1)]), _c('div', {
    staticClass: "form__row"
  }, [_c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "label": "NOMINAL VALUE",
      "displayFormat": "number",
      "min": 0,
      "validation": (_vm$$v8 = _vm.$v) === null || _vm$$v8 === void 0 ? void 0 : (_vm$$v8$subscription = _vm$$v8.subscription) === null || _vm$$v8$subscription === void 0 ? void 0 : (_vm$$v8$subscription$ = _vm$$v8$subscription.llcShares) === null || _vm$$v8$subscription$ === void 0 ? void 0 : _vm$$v8$subscription$.nominalValue,
      "disabled": _vm.isSubscriptionDisabled
    },
    model: {
      value: _vm.subscription.llcShares.nominalValue,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription.llcShares, "nominalValue", $$v);
      },
      expression: "subscription.llcShares.nominalValue"
    }
  })], 1), _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "label": "ISSUE VALUE",
      "displayFormat": "number",
      "min": 0,
      "validation": (_vm$$v9 = _vm.$v) === null || _vm$$v9 === void 0 ? void 0 : (_vm$$v9$subscription = _vm$$v9.subscription) === null || _vm$$v9$subscription === void 0 ? void 0 : (_vm$$v9$subscription$ = _vm$$v9$subscription.llcShares) === null || _vm$$v9$subscription$ === void 0 ? void 0 : _vm$$v9$subscription$.issueValue,
      "disabled": true
    },
    model: {
      value: _vm.subscription.llcShares.issueValue,
      callback: function callback($$v) {
        _vm.$set(_vm.subscription.llcShares, "issueValue", $$v);
      },
      expression: "subscription.llcShares.issueValue"
    }
  })], 1)])] : [_c('div', {
    staticClass: "series-wrapper"
  }, [_c('p', {
    class: ['title', !((_vm$subscription = _vm.subscription) !== null && _vm$subscription !== void 0 && (_vm$subscription$seri = _vm$subscription.seriesOfShares) !== null && _vm$subscription$seri !== void 0 && _vm$subscription$seri.length) ? 'mb-5' : 'mb-3']
  }, [_vm._v("Series of shares")]), _vm.viewType === 'add' && (_vm$seriesOfSharesLis = _vm.seriesOfSharesList) !== null && _vm$seriesOfSharesLis !== void 0 && _vm$seriesOfSharesLis.length ? _c('div', {
    staticClass: "series summary"
  }, [_c('subscription-series-table', {
    attrs: {
      "tableItems": _vm.seriesOfSharesList
    }
  })], 1) : _vm._e(), _c('div', {
    class: ['series', !_vm.isSubscriptionDisabled ? 'mb-5' : '']
  }, [_vm._l((_vm$subscription2 = _vm.subscription) === null || _vm$subscription2 === void 0 ? void 0 : _vm$subscription2.seriesOfShares, function (item, index) {
    var _vm$$v10, _vm$$v10$subscription, _vm$$v10$subscription2, _vm$$v10$subscription3, _vm$$v11, _vm$$v11$subscription, _vm$$v11$subscription2, _vm$$v11$subscription3, _vm$$v12, _vm$$v12$subscription, _vm$$v12$subscription2, _vm$$v12$subscription3, _vm$$v13, _vm$$v13$subscription, _vm$$v13$subscription2, _vm$$v13$subscription3, _vm$$v14, _vm$$v14$subscription, _vm$$v14$subscription2, _vm$$v14$subscription3, _vm$$v15, _vm$$v15$subscription, _vm$$v15$subscription2, _vm$$v15$subscription3;

    return _c('div', {
      key: index,
      class: ['series__group']
    }, [_c('div', {
      staticClass: "series__row"
    }, [_c('sygni-select', {
      staticClass: "series-name-select",
      attrs: {
        "label": "NAME",
        "value": item.name,
        "searchable": true,
        "addOptionBtn": true,
        "addOptionBtnText": "Add new series",
        "validation": (_vm$$v10 = _vm.$v) === null || _vm$$v10 === void 0 ? void 0 : (_vm$$v10$subscription = _vm$$v10.subscription) === null || _vm$$v10$subscription === void 0 ? void 0 : (_vm$$v10$subscription2 = _vm$$v10$subscription.seriesOfShares) === null || _vm$$v10$subscription2 === void 0 ? void 0 : (_vm$$v10$subscription3 = _vm$$v10$subscription2.$each[index]) === null || _vm$$v10$subscription3 === void 0 ? void 0 : _vm$$v10$subscription3.name,
        "disabled": _vm.isSubscriptionDisabled,
        "options": _vm.shareListOptions
      },
      on: {
        "addOption": function addOption($event) {
          return _vm.handleAddNewSeriesOption($event, item);
        },
        "input": function input($event) {
          return _vm.setSeriesName($event, item);
        }
      }
    }), _c('sygni-input', {
      attrs: {
        "label": "COUNTER FROM",
        "validation": (_vm$$v11 = _vm.$v) === null || _vm$$v11 === void 0 ? void 0 : (_vm$$v11$subscription = _vm$$v11.subscription) === null || _vm$$v11$subscription === void 0 ? void 0 : (_vm$$v11$subscription2 = _vm$$v11$subscription.seriesOfShares) === null || _vm$$v11$subscription2 === void 0 ? void 0 : (_vm$$v11$subscription3 = _vm$$v11$subscription2.$each[index]) === null || _vm$$v11$subscription3 === void 0 ? void 0 : _vm$$v11$subscription3.counterFrom,
        "displayFormat": "integer",
        "disabled": _vm.isSubscriptionDisabled
      },
      model: {
        value: item.counterFrom,
        callback: function callback($$v) {
          _vm.$set(item, "counterFrom", $$v);
        },
        expression: "item.counterFrom"
      }
    }), _c('sygni-input', {
      attrs: {
        "label": "COUNTER TO",
        "value": item.counterTo,
        "validation": (_vm$$v12 = _vm.$v) === null || _vm$$v12 === void 0 ? void 0 : (_vm$$v12$subscription = _vm$$v12.subscription) === null || _vm$$v12$subscription === void 0 ? void 0 : (_vm$$v12$subscription2 = _vm$$v12$subscription.seriesOfShares) === null || _vm$$v12$subscription2 === void 0 ? void 0 : (_vm$$v12$subscription3 = _vm$$v12$subscription2.$each[index]) === null || _vm$$v12$subscription3 === void 0 ? void 0 : _vm$$v12$subscription3.counterTo,
        "displayFormat": "integer",
        "disabled": _vm.isSubscriptionDisabled
      },
      on: {
        "input": function input($event) {
          return _vm.calculateInstrumentCount($event, item);
        }
      }
    }), _c('sygni-input', {
      attrs: {
        "label": "INSTRUMENT COUNT",
        "value": item.instrumentCount,
        "validation": (_vm$$v13 = _vm.$v) === null || _vm$$v13 === void 0 ? void 0 : (_vm$$v13$subscription = _vm$$v13.subscription) === null || _vm$$v13$subscription === void 0 ? void 0 : (_vm$$v13$subscription2 = _vm$$v13$subscription.seriesOfShares) === null || _vm$$v13$subscription2 === void 0 ? void 0 : (_vm$$v13$subscription3 = _vm$$v13$subscription2.$each[index]) === null || _vm$$v13$subscription3 === void 0 ? void 0 : _vm$$v13$subscription3.instrumentCount,
        "displayFormat": "integer",
        "min": 0,
        "disabled": _vm.isSubscriptionDisabled
      },
      on: {
        "input": function input($event) {
          return _vm.calculateCounterTo($event, item);
        }
      }
    }), _c('sygni-input', {
      attrs: {
        "label": "NOMINAL VALUE",
        "validation": (_vm$$v14 = _vm.$v) === null || _vm$$v14 === void 0 ? void 0 : (_vm$$v14$subscription = _vm$$v14.subscription) === null || _vm$$v14$subscription === void 0 ? void 0 : (_vm$$v14$subscription2 = _vm$$v14$subscription.seriesOfShares) === null || _vm$$v14$subscription2 === void 0 ? void 0 : (_vm$$v14$subscription3 = _vm$$v14$subscription2.$each[index]) === null || _vm$$v14$subscription3 === void 0 ? void 0 : _vm$$v14$subscription3.nominalValue,
        "displayFormat": "number",
        "disabled": _vm.isNominalValueDisabled(item, index)
      },
      on: {
        "input": function input($event) {
          return _vm.updateNominalValues(item);
        }
      },
      model: {
        value: item.nominalValue,
        callback: function callback($$v) {
          _vm.$set(item, "nominalValue", $$v);
        },
        expression: "item.nominalValue"
      }
    }), _c('sygni-input', {
      attrs: {
        "label": "ISSUE VALUE",
        "validation": (_vm$$v15 = _vm.$v) === null || _vm$$v15 === void 0 ? void 0 : (_vm$$v15$subscription = _vm$$v15.subscription) === null || _vm$$v15$subscription === void 0 ? void 0 : (_vm$$v15$subscription2 = _vm$$v15$subscription.seriesOfShares) === null || _vm$$v15$subscription2 === void 0 ? void 0 : (_vm$$v15$subscription3 = _vm$$v15$subscription2.$each[index]) === null || _vm$$v15$subscription3 === void 0 ? void 0 : _vm$$v15$subscription3.issueValue,
        "displayFormat": "number",
        "disabled": _vm.isSubscriptionDisabled
      },
      model: {
        value: item.issueValue,
        callback: function callback($$v) {
          _vm.$set(item, "issueValue", $$v);
        },
        expression: "item.issueValue"
      }
    }), !_vm.isSubscriptionDisabled ? _c('div', {
      class: ['series__action', index === 0 ? 'hidden' : ''],
      on: {
        "click": function click($event) {
          return _vm.removeSeriesOfSharesItem(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('DELETE')
      }
    })]) : _vm._e()], 1)]);
  }), !_vm.isSubscriptionDisabled ? _c('a', {
    staticClass: "series__btn text-danger",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addSeriesOfSharesItem.apply(null, arguments);
      }
    }
  }, [_vm._v("Add new series +")]) : _vm._e()], 2)])], _c('div', {
    class: ['form__row form__row--target', _vm.isLlc ? 'mt-4' : '']
  }, [_c('div', {
    staticClass: "form__col"
  }, [!_vm.isLlc ? [_c('sygni-input', {
    attrs: {
      "displayFormat": "number",
      "min": 0,
      "placeholder": "0,00",
      "disabled": true,
      "label": "INVESTMENT VALUE"
    },
    model: {
      value: _vm.computedTotalInvestmentValue,
      callback: function callback($$v) {
        _vm.computedTotalInvestmentValue = $$v;
      },
      expression: "computedTotalInvestmentValue"
    }
  })] : [_c('sygni-input', {
    attrs: {
      "displayFormat": "number",
      "min": 0,
      "placeholder": "0,00",
      "disabled": _vm.isSubscriptionDisabled,
      "label": "INVESTMENT VALUE"
    },
    model: {
      value: _vm.investmentTotalValue,
      callback: function callback($$v) {
        _vm.investmentTotalValue = $$v;
      },
      expression: "investmentTotalValue"
    }
  })]], 2), _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.usedCurrencies,
      "value": (_vm$subscription3 = _vm.subscription) === null || _vm$subscription3 === void 0 ? void 0 : _vm$subscription3.currency,
      "label": "",
      "disabled": true,
      "placeholder": ""
    }
  })], 1)]), _c('div', {
    staticClass: "form__row"
  }, [_c('attachments', {
    ref: "attachments",
    attrs: {
      "editMode": _vm.viewType === 'preview' ? 'preview' : 'edit',
      "singleFileMode": false,
      "readOnlyMode": _vm.isSubscriptionDisabled,
      "allowTemplates": false,
      "type": "documents",
      "protected": false,
      "uploadBtnText": "Upload agreement",
      "handlePaths": true
    },
    on: {
      "openAttachment": function openAttachment($event) {
        return _vm.openAttachmentPreview($event, true);
      },
      "change": _vm.handleAgreements
    },
    scopedSlots: _vm._u([{
      key: "heading",
      fn: function fn() {
        var _vm$$v16, _vm$$v16$subscription;

        return [_c('p', {
          staticClass: "title mb-1"
        }, [_vm._v("Agreement")]), _c('sygni-input-error', {
          staticClass: "mb-4 align-left",
          attrs: {
            "validation": (_vm$$v16 = _vm.$v) === null || _vm$$v16 === void 0 ? void 0 : (_vm$$v16$subscription = _vm$$v16.subscription) === null || _vm$$v16$subscription === void 0 ? void 0 : _vm$$v16$subscription.resolutionIds
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "form__row form__row--documents"
  }, [_c('attachments', {
    ref: "documents",
    attrs: {
      "editMode": _vm.viewType === 'preview' ? 'preview' : 'edit',
      "singleFileMode": false,
      "readOnlyMode": !_vm.isSubscriptionAuthorised || _vm.isMarketplaceAccountant || _vm.viewType === 'preview',
      "allowTemplates": false,
      "type": "documents",
      "protected": true,
      "uploadBtnText": "Upload attachments",
      "handlePaths": true
    },
    on: {
      "openAttachment": function openAttachment($event) {
        return _vm.openAttachmentPreview($event, false);
      },
      "change": _vm.handleAttachments
    },
    scopedSlots: _vm._u([{
      key: "heading",
      fn: function fn() {
        return [_c('p', {
          staticClass: "title mb-1"
        }, [_vm._v("Attachments")]), _c('sygni-input-error', {
          staticClass: "mb-4 align-left"
        })];
      },
      proxy: true
    }])
  })], 1), _c('attachment-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAttachmentPreviewModal,
      expression: "showAttachmentPreviewModal"
    }],
    attrs: {
      "useCdn": _vm.useCdn,
      "attachment": _vm.selectedAttachment
    },
    on: {
      "close": _vm.closeAttachmentPreviewModal
    }
  }), _c('div', {
    staticClass: "form__actions"
  }, [_vm.viewType !== 'preview' ? _c('sygni-rect-button', {
    staticClass: "filled gn-primary",
    on: {
      "click": _vm.doAction
    }
  }, [_vm._v(_vm._s(_vm.isEditMode ? 'Update' : 'Create') + " subscription")]) : _vm._e(), _c('sygni-link-button', {
    attrs: {
      "type": "simple"
    },
    on: {
      "click": function click($event) {
        _vm.$router.push({
          path: "/".concat(_vm.$route.path.includes('company') ? 'company' : 'fund', "/fundraising?page=subscriptions")
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.isEditMode || _vm.viewType === 'preview' ? 'Go back' : 'Cancel'))])], 1)], 2)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }