var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subscription-series-table"
  }, [_c('div', {
    staticClass: "subscription-series-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-inner-table sygni-b-table table",
    attrs: {
      "id": "fe_subscriptions_series_grid",
      "innertable": "true",
      "tbody-class": "fe_subscriptions_series_grid_tbody",
      "fields": _vm.tableFields,
      "items": _vm.filteredTableItems,
      "perPage": 0,
      "show-empty": true
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(seriesOfSharesName)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item2;

        return [_c('div', {
          staticClass: "series-of-shares-name text-left"
        }, [_vm._v(" " + _vm._s(((_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.seriesOfSharesName) || ((_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.name)) + " ")])];
      }
    }, {
      key: "cell(counterFrom)",
      fn: function fn(rowData) {
        var _rowData$item3;

        return [_c('div', {
          staticClass: "counter-from text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("thousandSeparator")(((_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.counterFrom) || 0)) + " ")])];
      }
    }, {
      key: "cell(counterTo)",
      fn: function fn(rowData) {
        var _rowData$item4;

        return [_c('div', {
          staticClass: "counter-to text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("thousandSeparator")(((_rowData$item4 = rowData.item) === null || _rowData$item4 === void 0 ? void 0 : _rowData$item4.counterTo) || 0)) + " ")])];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        var _rowData$item5;

        return [_c('div', {
          staticClass: "instrument-count text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("thousandSeparator")(((_rowData$item5 = rowData.item) === null || _rowData$item5 === void 0 ? void 0 : _rowData$item5.instrumentCount) || 0)) + " ")])];
      }
    }, {
      key: "cell(nominalValue)",
      fn: function fn(rowData) {
        var _rowData$item6, _rowData$item7;

        return [_c('div', {
          staticClass: "instrument-count text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.getTotal(((_rowData$item6 = rowData.item) === null || _rowData$item6 === void 0 ? void 0 : _rowData$item6.nominalValue) || 0, (rowData === null || rowData === void 0 ? void 0 : (_rowData$item7 = rowData.item) === null || _rowData$item7 === void 0 ? void 0 : _rowData$item7.instrumentCount) || 0) || 0, 2)) + " ")])];
      }
    }, {
      key: "cell(issueValue)",
      fn: function fn(rowData) {
        var _rowData$item8, _rowData$item9;

        return [_c('div', {
          staticClass: "instrument-count text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.getTotal(((_rowData$item8 = rowData.item) === null || _rowData$item8 === void 0 ? void 0 : _rowData$item8.issueValue) || 0, (rowData === null || rowData === void 0 ? void 0 : (_rowData$item9 = rowData.item) === null || _rowData$item9 === void 0 ? void 0 : _rowData$item9.instrumentCount) || 0) || 0, 2)) + " ")])];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }