





















































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import { Prop } from 'vue-property-decorator';
import { create, all } from 'mathjs'

const math = create(all)

@Component({
  components: { SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse }
})
export default class SubscriptionSeriesTable extends SygniTable<any> {
  @Prop({ default: [] }) tableItems: any[]

  tableFields = [
    { key: 'seriesOfSharesName', label: 'Name', sortable: false, class: ['series-of-shares-name', 'text-left'] },
    { key: 'counterFrom', label: 'Counter From', sortable: false, class: ['counter-from', 'text-right'] },
    { key: 'counterTo', label: 'Counter To', sortable: false, class: ['counter-to', 'text-right'] },
    { key: 'instrumentCount', label: 'Instrument Count', sortable: false, class: ['instrument-count', 'text-right'] },
    { key: 'nominalValue', label: 'Nominal Value', sortable: false, class: ['nominal-value', 'text-right'] },
    // { key: 'issueValue', label: 'Issue Value', sortable: false, class: ['issue-value', 'text-right'] },
  ]

  get filteredTableItems() {
    return this.tableItems?.filter((item: any) => {
      return !!((item?.seriesOfSharesName || item?.name) && item?.counterFrom && item?.counterTo && item?.instrumentCount && item?.nominalValue)
    })
  }

  get filtersQuery() {
    return this.$store.getters['products/getProductsTableFiltersQuery'];
  }

  getTooltipMessage(message: string) {
    return message?.length <= 35 ? '' : message
  }

  getTotal(value: number, multiplier: number) {
    const totalValue = math.multiply(math.bignumber(value), math.bignumber(multiplier))

    return totalValue || 0
  }

  async getItems() {
    // 
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    this.onMounted();
  }
}

